import {
  ApiDataResponse,
  CreditBalanceDTO,
  CreditBalanceResponse,
  OrganizationDTO,
  OrganizationEventListDto,
  OrganizationEventListResponse,
  OrganizationInviteDTO,
  OrganizationInviteResponse,
  OrganizationResponse,
  OrganizationUserListDto,
  OrganizationUserListResponse,
  RoleListDto,
  RoleListResponse,
} from "@root/utils/dtos";
import { ApiTransport } from "./config/apiTransport";
import { Organization } from "@root/utils/entities";
import { Pagination } from "@root/utils/types";

export class OrganizationService {
  private readonly _apiTransport: ApiTransport;

  constructor(sessionCookie?: string) {
    this._apiTransport = new ApiTransport(sessionCookie);
  }

  addOrganization = (organization: Organization): OrganizationResponse =>
    this._apiTransport.post<OrganizationDTO>(
      `/api/v1/organizations`,
      organization
    );

  inviteUser = (
    organizationId: string,
    roleId: string,
    email: string
  ): Promise<ApiDataResponse<unknown>> =>
    this._apiTransport.post(`/api/v1/organizations/${organizationId}/invites`, {
      roleId,
      email,
    });

  acceptInvite = (
    organizationId: string,
    inviteId: string
  ): Promise<ApiDataResponse<unknown>> =>
    this._apiTransport.post(
      `/api/v1/organizations/${organizationId}/invites/${inviteId}`,
      null
    );

  updateOrganization = (organization: Organization): OrganizationResponse =>
    this._apiTransport.put<OrganizationDTO>(
      `/api/v1/organizations/${organization.id}`,
      organization
    );

  updateOrganizationUser = (
    organizationId: string,
    userId: string,
    roleId: string
  ): OrganizationResponse =>
    this._apiTransport.put<OrganizationDTO>(
      `/api/v1/organizations/${organizationId}/users/${userId}`,
      { roleId }
    );

  getOrganization = (id: string): OrganizationResponse =>
    this._apiTransport.get<OrganizationDTO>(`/api/v1/organizations/${id}`);

  getOrganizationInvite = (
    id: string,
    inviteId: string
  ): OrganizationInviteResponse =>
    this._apiTransport.get<OrganizationInviteDTO>(
      `/api/v1/organizations/${id}/invites/${inviteId}`
    );

  getOrganizationUsers = (id: string): OrganizationUserListResponse =>
    this._apiTransport.get<OrganizationUserListDto>(
      `/api/v1/organizations/${id}/users?includeInvites=true`
    );

  getOrganizationFollowers = (id: string): OrganizationUserListResponse =>
    this._apiTransport.get<OrganizationUserListDto>(
      `/api/v1/organizations/${id}/followers`
    );

  getOrganizationEvents = (id: string, page: number): OrganizationEventListResponse =>
    this._apiTransport.get<
      OrganizationEventListDto & { pagination: Pagination }
    >(`/api/v1/organizations/${id}/events?page=${page}`);

  getOrganizationRoles = (): RoleListResponse =>
    this._apiTransport.get<RoleListDto>(`/api/v1/organizations/roles`);

  getCreditBalance = (id: string): CreditBalanceResponse =>
    this._apiTransport.get<CreditBalanceDTO>(
      `/api/v1/organizations/${id}/credit-balance`
    );

  removeUser = (organizationId: string, userId: string) =>
    this._apiTransport.delete(
      `/api/v1/organizations/${organizationId}/users/${userId}`
    );

  deleteOrganization = (organizationId: string) =>
    this._apiTransport.delete(`/api/v1/organizations/${organizationId}`);
}

export const organizationService = new OrganizationService();
